import { gql } from "@apollo/client";
import classNames from "classnames";
import { ScissorsImage } from "components/ScissorsImage";
import { useGACampaignQueries } from "contexts/gaCampaign";
import {
  EpisodeNumberContentFragment,
  EpisodeNumberContentFragmentDoc,
  EpisodeThumbnailContentFragment,
  EpisodeThumbnailContentFragmentDoc,
  MangaItemHotNewEpisodeContentFragment,
  MangaItemHotSaleEpisodeContentFragment,
  MangaItemHotWorkContentFragment,
  MangaItemNewWorkContentFragment,
  MangaItemRankingWorkContentFragment,
  MangaItemTagWorkContentFragment,
  PriceDisplayContentFragment,
  PriceDisplayContentFragmentDoc,
  StatusPageViewContentFragment,
  StatusPageViewContentFragmentDoc,
  StatusWorkContentFragment,
  StatusWorkContentFragmentDoc,
  TitleUserContentFragment,
  TitleUserContentFragmentDoc,
  UserContentFragment,
  UserContentFragmentDoc,
  WorkThumbnailContentFragment,
  WorkThumbnailContentFragmentDoc,
  WorkTitleContentFragment,
  WorkTitleContentFragmentDoc,
  WorkTitleWithPublishedAtContentFragment,
} from "generated/graphql";
import {
  dateIsWithinNDays,
  formatDateMedium,
  formatDateShort,
} from "lib/formatter";
import { rr } from "lib/reverseRouter";
import Link from "next/link";
import React from "react";
import { FormattedNumber } from "react-intl";
import styles from "./MangaItem.module.scss";
import { UserIcon } from "./UserIcon";

// 作品サムネイル

gql`
  fragment WorkThumbnailContent on Work {
    id
    title
    coverImage {
      id
      url
    }
  }
`;

interface WorkThumbnailProps {
  work: WorkThumbnailContentFragment;
}

const WorkThumbnail: React.FC<WorkThumbnailProps> = ({ work }) => {
  const campaignQueries = useGACampaignQueries();
  const index = campaignQueries
    ? rr.works.index({
        workId: work.id,
        ...campaignQueries,
      })
    : rr.works.index({ workId: work.id });
  return (
    <Link
      {...index}
      target="_blank"
      rel="noopener noreferrer"
      className={styles.thumb_container}
    >
      <div className={styles.thumb_item}>
        <ScissorsImage
          templateUrl={work.coverImage.url}
          className={styles.thumb}
          width={720}
          height={405}
          alt={work.title}
        />
      </div>
    </Link>
  );
};

// WorkThumbnailより小さいサイズの作品サムネイル

const SmallWorkThumbnail: React.FC<WorkThumbnailProps> = ({ work }) => {
  return (
    <div className={styles.small_thumb_cotainer}>
      <WorkThumbnail work={work} />
    </div>
  );
};

// 話の1ページ目

gql`
  fragment EpisodeThumbnailContent on Episode {
    id
    pages(first: 0) {
      thumbnailImage {
        id
        url
      }
    }
    salesInfo {
      pagesChargedFrom
    }
    purchasedByViewer
    work {
      id
      title
    }
  }
`;

interface EpisodeThumbnailProps {
  episode: EpisodeThumbnailContentFragment;
}
const EpisodeThumbnail: React.FC<EpisodeThumbnailProps> = ({ episode }) => {
  return (
    <Link
      {...rr.episodes.episode.index({ episodeId: episode.id })}
      target="_blank"
      rel="noopener noreferrer"
      className={styles.page_container}
    >
      <div className={styles.page_item}>
        <ScissorsImage
          templateUrl={episode.pages.thumbnailImage.url}
          width={960}
          height={1344}
          alt={episode.work.title}
          className={
            // 最初から有料話かつ未購入の場合はブラーあり画像を表示
            classNames(styles.page, {
              [styles.page_blur]:
                !episode.purchasedByViewer &&
                episode.salesInfo?.pagesChargedFrom === 0,
            })
          }
        />
      </div>
    </Link>
  );
};

// 作品タイトルリンク

gql`
  fragment WorkTitleContent on Work {
    id
    title
  }
`;

interface WorkTitleProps {
  work: WorkTitleContentFragment;
}

const WorkTitle: React.FC<WorkTitleProps> = ({ work }) => {
  const campaignQueries = useGACampaignQueries();
  const index = campaignQueries
    ? rr.works.index({
        workId: work.id,
        ...campaignQueries,
      })
    : rr.works.index({ workId: work.id });

  return (
    <Link
      {...index}
      target="_blank"
      rel="noopener noreferrer"
      className={styles.work_title_link}
    >
      <h3 className={styles.work_title_box}>
        <span className={styles.work_title}>{work.title}</span>
      </h3>
    </Link>
  );
};

// 作品タイトル＋更新ラベルリンク

gql`
  fragment WorkTitleWithPublishedAtContent on Work {
    id
    title
  }
`;

interface WorkTitleWithPublishedAtProps {
  work: WorkTitleWithPublishedAtContentFragment;
  publishedAt: string;
}

const WorkTitleWithPublishedAt: React.FC<WorkTitleWithPublishedAtProps> = ({
  work,
  publishedAt,
}) => {
  // publishedAtが一週間以内であれば更新ラベルを表示する
  const campaignQueries = useGACampaignQueries();
  const index = campaignQueries
    ? rr.works.index({
        workId: work.id,
        ...campaignQueries,
      })
    : rr.works.index({ workId: work.id });
  const showUpdatedLabel = dateIsWithinNDays(publishedAt, 7);

  return (
    <Link
      {...index}
      target="_blank"
      rel="noopener noreferrer"
      className={styles.work_title_link}
    >
      <h3 className={styles.work_title_box}>
        {showUpdatedLabel && <span className={styles.label_update}>更新</span>}
        <span className={styles.work_title}>{work.title}</span>
      </h3>
    </Link>
  );
};

// 作者リンク

gql`
  fragment UserContent on Work {
    id
    user {
      id
      screenName
      displayName
      iconImage {
        id
        url
      }
    }
  }
`;

interface UserProps {
  work: UserContentFragment;
}

const User: React.FC<UserProps> = ({ work }) => {
  return (
    <Link
      {...rr.site.index({
        userScreenName: work.user.screenName,
      })}
      target="_blank"
      rel="noopener noreferrer"
      className={styles.user_item}
    >
      <div className={styles.user}>
        <UserIcon userIconUrl={work.user.iconImage?.url} size={48} />
      </div>
      <h4 className={styles.user_name}>{work.user.displayName}</h4>
    </Link>
  );
};

// 作品タイトル + 作者名
gql`
  fragment TitleUserContent on Work {
    id
    ...WorkTitleContent
    ...UserContent
  }
  ${WorkTitleContentFragmentDoc}
  ${UserContentFragmentDoc}
`;

interface TitleUserProps {
  work: TitleUserContentFragment;
}
const TitleUser: React.FC<TitleUserProps> = ({ work }) => {
  return (
    <div>
      <WorkTitle work={work} />
      <User work={work} />
    </div>
  );
};

// 順位
interface RankingNumberProps {
  rankingNumber: number;
}
const RankingNumber: React.FC<RankingNumberProps> = ({ rankingNumber }) => {
  const rankingStyle = (() => {
    switch (rankingNumber) {
      case 1:
        return styles.rank_1;
      case 2:
        return styles.rank_2;
      case 3:
        return styles.rank_3;
      default:
        return undefined;
    }
  })();
  return (
    <div className={classNames(styles.ranking_number, rankingStyle)}>
      {rankingNumber}
    </div>
  );
};

// 連載中 or 完結
gql`
  fragment StatusWorkContent on Work {
    id
    isCompleted
  }
`;
interface StatusWorkProps {
  work: StatusWorkContentFragment;
}
const StatusWork: React.FC<StatusWorkProps> = ({ work }) => {
  return (
    <span className={styles.work_status}>
      {work.isCompleted ? "完結" : "連載中"}
    </span>
  );
};

// 話数
gql`
  fragment EpisodeNumberContent on Episode {
    number
  }
`;
interface EpisodeNumberProps {
  episode: EpisodeNumberContentFragment;
}
const EpisodeNumber: React.FC<EpisodeNumberProps> = ({ episode }) => {
  return <span className={styles.episode_status}>{`${episode.number}話`}</span>;
};

// PV数
gql`
  fragment StatusPageViewContent on WorkStatistic {
    viewCount
  }
`;
interface StatusPageViewProps {
  workStatistic: StatusPageViewContentFragment;
}

// TODO(design): 今後使う可能性があるので残しておく
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StatusPageView: React.FC<StatusPageViewProps> = ({ workStatistic }) => {
  return <span className={styles.status_pv}>{workStatistic.viewCount}PV</span>;
};

// いいね数
interface StatusGoodProps {
  likeCount: number;
}
const StatusGood: React.FC<StatusGoodProps> = ({ likeCount }) => {
  return <span className={styles.status_good}>{likeCount}</span>;
};

// 価格 or 無料で読めるページ数 + 価格 or 購入済み の表示

gql`
  fragment PriceDisplayContent on Episode {
    id
    salesInfo {
      price
      pagesChargedFrom
    }
    purchasedByViewer
  }
`;

interface PriceDisplayProps {
  episode: PriceDisplayContentFragment;
}

const PriceDisplay: React.FC<PriceDisplayProps> = ({ episode }) => {
  const salesInfo = episode.salesInfo;
  const purchasedByViewer = episode.purchasedByViewer;

  if (!salesInfo) return null;

  return (
    <div className={styles.price_container}>
      {/* 購入済みの場合は以下を表示 */}
      {purchasedByViewer && <span className={styles.purchased}>購入済み</span>}
      {/* 購入しておらず、途中から有料話のときはページ数を表示 */}
      {!purchasedByViewer && salesInfo && salesInfo.pagesChargedFrom >= 1 && (
        <span className={styles.page_num}>
          {salesInfo.pagesChargedFrom}ページまで無料
        </span>
      )}
      {/* 購入しておらず、有料話のときは価格を表示 */}
      {!purchasedByViewer && salesInfo && (
        <span className={styles.price}>
          <FormattedNumber
            value={salesInfo.price}
            style="currency"
            currency="JPY"
          />
        </span>
      )}
    </div>
  );
};

// ランキング
gql`
  fragment MangaItemRankingWorkContent on Work {
    id
    episodes(first: 1, direction: DESC) {
      edges {
        node {
          id
          publishedAt
          ...EpisodeNumberContent
        }
      }
    }
    statistic {
      likeCount
      ...StatusPageViewContent
    }
    ...StatusWorkContent
    ...TitleUserContent
    ...WorkThumbnailContent
  }
  ${EpisodeNumberContentFragmentDoc}
  ${StatusPageViewContentFragmentDoc}
  ${StatusWorkContentFragmentDoc}
  ${TitleUserContentFragmentDoc}
  ${WorkThumbnailContentFragmentDoc}
`;
interface MangaItemRankingWorkProps {
  work: MangaItemRankingWorkContentFragment;
  rankingNumber: number;
}
export const MangaItemRankingWork: React.FC<MangaItemRankingWorkProps> = ({
  work,
  rankingNumber,
}) => {
  // 作品には必ずエピソードが1話はあるので `!` を使う
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const currentEpisode = work.episodes.edges![0].node;
  // Work の episodes から取れるものは「公開中エピソード一覧」であるため、publishedAt が存在していると見なす
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const publishedAt = currentEpisode.publishedAt!;

  return (
    <div className={styles.work_item}>
      <RankingNumber rankingNumber={rankingNumber} />
      <WorkThumbnail work={work} />
      <div className={styles.work_detail}>
        <WorkTitleWithPublishedAt work={work} publishedAt={publishedAt} />
        <User work={work} />
        <ul className={styles.work_detail_list}>
          <li>
            <StatusWork work={work} />
          </li>
          <li>
            <span className={styles.episode_status}>全</span>
            <EpisodeNumber episode={currentEpisode} />
          </li>
          <li>
            <span className={styles.episode_status}>
              <time>{formatDateMedium(publishedAt)}</time>更新
            </span>
          </li>
          {/*
          <li>
            <StatusPageView workStatistic={work.statistic} />
          </li>
          <li>
            <StatusGood likeCount={work.statistic.likeCount} />
          </li>
          */}
        </ul>
      </div>
    </div>
  );
};

// 新着・更新作品

gql`
  fragment MangaItemNewWorkContent on Work {
    id
    description
    ...WorkThumbnailContent
    ...TitleUserContent
  }
  ${WorkThumbnailContentFragmentDoc}
  ${TitleUserContentFragmentDoc}
`;

type MangaItemNewWorkProps = {
  work: MangaItemNewWorkContentFragment;
};

export const MangaItemNewWork: React.FC<MangaItemNewWorkProps> = ({ work }) => {
  return (
    <div className={styles.work_item}>
      <WorkThumbnail work={work} />
      <div className={styles.work_detail}>
        <TitleUser work={work} />
        <p className={styles.work_description}>{work.description}</p>
      </div>
    </div>
  );
};

// 注目の無料話

gql`
  fragment MangaItemHotNewEpisodeContent on Episode {
    likeCount
    ...EpisodeNumberContent
    ...EpisodeThumbnailContent
    work {
      ...StatusWorkContent
      ...TitleUserContent
    }
  }
  ${EpisodeNumberContentFragmentDoc}
  ${EpisodeThumbnailContentFragmentDoc}
  ${StatusWorkContentFragmentDoc}
  ${TitleUserContentFragmentDoc}
`;

interface MangaItemHotNewEpisodeProps {
  episode: MangaItemHotNewEpisodeContentFragment;
}

export const MangaItemHotNewEpisode: React.FC<MangaItemHotNewEpisodeProps> = ({
  episode,
}) => {
  return (
    <div className={styles.episode_item}>
      <div className={styles.thumbnail_wrapper}>
        <EpisodeThumbnail episode={episode} />
      </div>
      <div>
        <TitleUser work={episode.work} />
        <ul className={styles.work_detail_list}>
          <li>
            <StatusWork work={episode.work} />
          </li>
          <li className={styles.dot_none}>
            <EpisodeNumber episode={episode} />
          </li>
          <li>
            <StatusGood likeCount={episode.likeCount} />
          </li>
        </ul>
      </div>
    </div>
  );
};

// 注目の作品
gql`
  fragment MangaItemHotWorkContent on Work {
    ...TitleUserContent
    firstEpisode: episodes(first: 1) {
      edges {
        node {
          id
          status
          ...EpisodeThumbnailContent
          ...PriceDisplayContent
        }
      }
    }
    latestEpisode: episodes(first: 1, direction: DESC) {
      edges {
        node {
          id
          publishedAt
          ...EpisodeNumberContent
        }
      }
    }
  }
  ${TitleUserContentFragmentDoc}
  ${EpisodeThumbnailContentFragmentDoc}
  ${PriceDisplayContentFragmentDoc}
`;

type MangaItemHotWorkProps = {
  work: MangaItemHotWorkContentFragment;
};

export const MangaItemHotWork: React.FC<MangaItemHotWorkProps> = ({ work }) => {
  // 作品には必ずエピソードが1話はあるので `!` を使う
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const episode = work.firstEpisode.edges![0].node;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const latestEpisode = work.latestEpisode.edges![0].node;
  // Work の episodes から取れるものは「公開中エピソード一覧」であるため、publishedAt が存在していると見なす
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const publishedAt = latestEpisode.publishedAt!;

  return (
    <div className={styles.episode_item}>
      <div className={styles.thumbnail_wrapper}>
        <PriceDisplay episode={episode} />
        <EpisodeThumbnail episode={episode} />
      </div>
      <div className={styles.text_white}>
        <div>
          <WorkTitleWithPublishedAt work={work} publishedAt={publishedAt} />
        </div>
        <ul className={styles.work_detail_list}>
          <li>
            {/* MangaItemHotWork内のUser周りは他と違う記述がしたいため <User /> は使わない */}
            <Link
              {...rr.site.index({
                userScreenName: work.user.screenName,
              })}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.user_item}
            >
              <div className={styles.user}>
                <UserIcon userIconUrl={work.user.iconImage?.url} size={48} />
              </div>
              <h4 className={styles.user_name_overflow}>
                {work.user.displayName}
              </h4>
            </Link>
          </li>
          <li>
            <span className={styles.episode_status}>全</span>
            <EpisodeNumber episode={latestEpisode} />
          </li>
          <li>
            <span className={styles.episode_status}>
              <time>{formatDateShort(publishedAt)}</time>更新
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

// 注目の有料話
gql`
  fragment MangaItemHotSaleEpisodeContent on Episode {
    id
    ...EpisodeNumberContent
    ...EpisodeThumbnailContent
    ...PriceDisplayContent
    work {
      id
      ...StatusWorkContent
      ...TitleUserContent
    }
  }
  ${EpisodeNumberContentFragmentDoc}
  ${EpisodeThumbnailContentFragmentDoc}
  ${PriceDisplayContentFragmentDoc}
  ${StatusWorkContentFragmentDoc}
  ${TitleUserContentFragmentDoc}
`;

interface MangaItemHotSaleEpisodeProps {
  episode: MangaItemHotSaleEpisodeContentFragment;
}

export const MangaItemHotSaleEpisode: React.FC<
  MangaItemHotSaleEpisodeProps
> = ({ episode }) => {
  return (
    <div className={styles.episode_item}>
      <div className={styles.thumbnail_wrapper}>
        <PriceDisplay episode={episode} />
        <EpisodeThumbnail episode={episode} />
      </div>
      <div>
        <TitleUser work={episode.work} />
        <ul className={styles.work_detail_list}>
          <li>
            <StatusWork work={episode.work} />
          </li>
          <li>
            <EpisodeNumber episode={episode} />
          </li>
        </ul>
      </div>
    </div>
  );
};

// タグの作品
gql`
  fragment MangaItemTagWorkContent on Work {
    id
    episodes(first: 1, direction: DESC) {
      edges {
        node {
          id
          ...EpisodeNumberContent
        }
      }
    }
    ...StatusWorkContent
    ...TitleUserContent
    ...WorkThumbnailContent
    tags {
      id
      title
    }
  }
  ${EpisodeNumberContentFragmentDoc}
  ${StatusWorkContentFragmentDoc}
  ${TitleUserContentFragmentDoc}
  ${WorkThumbnailContentFragmentDoc}
`;

type MangaItemTagWorkProps = {
  work: MangaItemTagWorkContentFragment;
};

/**
 * タグのついた作品一覧に表示する作品コンポーネント
 */
export const MangaItemTagWork: React.FC<MangaItemTagWorkProps> = ({ work }) => {
  // 作品には必ずエピソードが1話はあるので `!` を使う
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const currentEpisode = work.episodes.edges![0].node;

  return (
    <div className={styles.work_item}>
      <SmallWorkThumbnail work={work} />
      <div className={styles.work_detail}>
        <TitleUser work={work} />
        <ul className={styles.work_detail_list}>
          <li>
            <StatusWork work={work} />
          </li>
          <li>
            <span className={styles.episode_status}>全</span>
            <EpisodeNumber episode={currentEpisode} />
          </li>
        </ul>
        <ul className={styles.tag_detail_list}>
          {work.tags?.map((workTag) => (
            <li key={workTag?.id}>
              <span className={styles.tag_words}>{workTag?.title}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
