import { ScissorsImage } from "components/ScissorsImage";
import React from "react";
import styles from "./UserIcon.module.scss";

export type UserIconProps = {
  userIconUrl?: string;
  size: number;
};

export const UserIcon: React.FC<UserIconProps> = ({ userIconUrl, size }) => {
  if (userIconUrl) {
    return (
      <span className={styles.container}>
        <ScissorsImage templateUrl={userIconUrl} width={size} height={size} />
      </span>
    );
  } else {
    return (
      <span className={styles.container}>
        <img src="/images/user_icon.svg" width={size} height={size} />
      </span>
    );
  }
};
